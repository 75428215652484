
import { ContentLoader } from 'vue-content-loader';
import { defineComponent, onMounted, ref, watch } from "vue";
import ApexChart from "apexcharts";

export default defineComponent({
name: "ChecklistLogCard-log",
components: { ContentLoader },
props: {
  loadingTable: Boolean,
  data: Array,
},

setup(props) {
  // const labels = ["Desejo negociar", "Preço", "Não desejo", "Realizar em outra loja", "Realizar na próxima", "Garantia"]
  // const colors = ["#22B9FF", "#2BACE8", "#34A5D9", "#3F9DC8", "#4991B2", "#477B93", "#4B6E7D", "#79A8BD", "#A2CDE1", "#d3f2ff", "#6674ff", "#727cf4", "#7c85ed", "#878ee4", "#336666", "#9095d9", "#8f92c7", "#9295b8", "#b6b8df", "#cfd1f1", "#6674ff", "#6674ff", "#7c85ed", "#878ee4", "#9095d9", "#8f92c7", "#9295b8", "#b6b8df", "#cfd1f1", "#d3d6ff", "#b766ff", "#b472f4", "#b387e4", "#b190d9", "#a88fc7", "#a292b8", "#c8b6df", "#decff1", "#e6d3ff"];
  const colors = ['#d34141', '#3734e5', '#21bc46', '#deed12', '#ed12e2', '#6fe9f7', '#bdeaa6','#f4ae22','#6e0be0','#1ad8b5','#eac5cd','#1d4c10','#a0924b','#0e2860','#642a6b'];
  const labels:any = ref([]);
  const series:any = ref([]);
  //declaração de variaveis//
  let chartInstance;
  let chartMobileInstance;
  const image = "https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/shapes/abstract-1.svg";

  function geraOptions(labelsRef, refColors, isMobile = false) {
    const newOptions = {
      dataLabels: {
        enabled: true,
      },
      chart: {
        width: '100%',
        height: 230,
        type: 'donut',
        options: "chart",
        offsetY: -10,
        series: [],
      },
      stroke: {
        width: 0
      },
      legend: {
        horizontalAlign: 'left', 
        position: isMobile ? 'bottom' : 'right'
      },
      labels: labelsRef,
      colors: refColors,
      fill: {
        colors: refColors,
      },
    }

    return newOptions;
  }

  watch(() => props.data, () => {
    if(props.data){
      const motivos:Array<any> = [...props.data];
      const motivosWithValue = motivos.filter((motivo, index) => motivo.qtd > 0);

      series.value = motivosWithValue.map(motivo => parseInt(motivo.qtd, 10));
      labels.value = motivosWithValue.map(motivo => {
        let nomeTratado = motivo.oferta[0].toUpperCase() + motivo.oferta.slice(1).toLowerCase();
        return nomeTratado;
      });

      let colorsData:any = [];
      motivos.forEach((motivo, index) => {
        if(motivo.qtd > 0){
          colorsData.push(colors[index]);
        }
      });
    

      setTimeout(() => {
        chartInstance.updateOptions(geraOptions(labels.value, colorsData));
        chartInstance.updateSeries(series.value);
        chartMobileInstance.updateOptions(geraOptions(labels.value, colorsData, true));
        chartMobileInstance.updateSeries(series.value);
        resizeWithTime();
      }, 500);
    }
  });

  onMounted(async () => {
    const options = geraOptions([""], ["#22B9FF"]);
    chartInstance = new ApexChart(document.querySelector("#grafico-utilizacao-app"), {
      ...options,
      series: [0, 0, 0, 0, 0],
    });
    chartMobileInstance = new ApexChart(document.querySelector("#grafico-utilizacao-app-mobile"), {
      ...options,
      series: [0, 0, 0, 0, 0],
    });
    chartInstance.render();
    chartMobileInstance.render();
  });

  function resizeWithTime(time = 100){
    setTimeout(() => window.dispatchEvent(new Event('resize')), time)
  }

  return {
    image,
    colors,
    labels,
    series,
  };
},
});
