
import { ContentLoader } from 'vue-content-loader';
import { defineComponent, onMounted, ref, watch } from "vue";
import ApexChart from "apexcharts";

export default defineComponent({
  name: "Acesso-log",
  components: { ContentLoader },
  props: {
    loadingTable: Boolean,
    data: Array,
  },

  setup(props) {
    const colors = ['#d34141', '#3734e5', '#21bc46', '#deed12', '#ed12e2', '#6fe9f7', '#bdeaa6','#f4ae22','#6e0be0','#1ad8b5','#eac5cd','#1d4c10','#a0924b','#0e2860','#642a6b'];
    const labels = ref<string[]>([]);
    const series = ref<any[]>([]);

    let chartInstance;
    let chartMobileInstance;
    const image = "https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/shapes/abstract-1.svg";

    function geraOptions(labelsRef, refColors, isMobile = false) {
      const newOptions = {
        chart: {
          height: 260,
          type: "scatter",
          offsetY: -20,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "40%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
					xaxis: {
						lines: {
							show: true
						}
					},
					yaxis: {
						lines: {
							show: true
						}
					},
				},

        xaxis: {
            tickAmount: 12,
            min: 7,
            max: 19,
            labels: {},
        
        },
        yaxis: {

          tickAmount: 12,
          min: 0,
          max: 60, // 

        },
        legend:{
					showForSingleSeries: true,
				},
        tooltip: {
          custom: function({ seriesIndex, dataPointIndex, w }) {
            const nomeUsuario = w.config.series[seriesIndex].name;
            const dataPonto = w.config.series[seriesIndex].data[dataPointIndex];
            const hora = dataPonto[0];
            const minutos = dataPonto[1];
            const paginaAcessada = dataPonto[2];

            return (
              '<div class="arrow_box">'+
                  '<div class="apexcharts-tooltip-title" style="font-weight: bold; font-family: Helvetica, Arial, sans-serif; font-size: 10px;">'
                    + nomeUsuario + '</div>'+
                  '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">'+
                      '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 9px;">'+
                        '<div class="apexcharts-tooltip-y-group">'+
                          '<span>' + 
                            paginaAcessada + 
                          '</span>' +
                        '</div>'+
                      '<div class="apexcharts-tooltip-z-group">'+
                        '<span>Hora: '+ hora + ':' + minutos + '</span>'+
                      '</div>'+
                    '</div>'+
                  '</div>'+
              '</div>'
            );
          }
        },
        fill: {
              opacity: 1,
            },
        colors: ["#22B9FF"],
        };

      return newOptions;
    }

    watch(() => props.data, () => {
      if (props.data) {
        const motivos: any[] = [...props.data];
        // Filtrar objetos com qtdAcessos maior que zero
        const objetosComAcessos = motivos.filter(objeto => objeto.qtdAcessos > 0);

        // Filtrar objetos com log com tamanho maior que zero
        const objetosComLogNaoVazio = objetosComAcessos.filter(objeto => objeto.log.length > 0);

        // Resultado

        // Extrair a hora, minutos e descrição da propriedade data e criar um novo array
        const novoArray = objetosComLogNaoVazio.map(objeto => {
          const novoLog = objeto.log.map(log => {
            const data = new Date(log.data);
            const hora = data.getHours().toString().padStart(2, '0');
            const minuto = data.getMinutes().toString().padStart(2, '0');
            return [hora, minuto, log.descricao];
          });
          return { ...objeto, data: novoLog };
        });

        // Remover a propriedade log de cada objeto no array resultante
        const novoArraySemLog :any= novoArray.map(objeto => {
          const { log, ...resto } = objeto;
          return resto;
        });

        // Resultado
        const nomes = novoArraySemLog.map(item => item.nome);
        if(novoArraySemLog.length>0){
          series.value = novoArraySemLog.map(item => {
              return {
                name: item.nome,
                data: item.data,
                
              color: colors[nomes.indexOf(item.nome) % colors.length],
            };
          });
        }



        setTimeout(() => {
          chartInstance.updateOptions(geraOptions(labels.value, colors));
          chartInstance.updateSeries(series.value);
          chartMobileInstance.updateOptions(geraOptions(labels.value, colors, true));
          chartMobileInstance.updateSeries(series.value);
          resizeWithTime();
          resizeWithTime();
        }, 500);
        
      }
      
    });

    

    onMounted(async () => {
      const options = geraOptions([""], ["#22B9FF"]);
      chartInstance = new ApexChart(document.querySelector("#grafico-oferta-pacote"), {
        ...options,
        series: [0, 0, 0, 0, 0],
      });
      chartMobileInstance = new ApexChart(document.querySelector("#grafico-oferta-pacote-mobile"), {
        ...options,
        series: [0, 0, 0, 0, 0],
      });
      chartInstance.render();
      chartMobileInstance.render();
    });

    function resizeWithTime(time = 100) {
      setTimeout(() => window.dispatchEvent(new Event('resize')), time)
    }

    return {
      image,
      colors,
      labels,
      series,
    };
  },
});
