
import { ref, watch, reactive, PropType , computed } from "vue";
import { ContentLoader } from 'vue-content-loader';
import store from "@/store/index";
import { hideModal } from '@/core/helpers/dom';
import { GetUsuarios } from '@/models/LogsModel'

export default {
  name: "LogdeVisualizacaoModal",
  props: {
    id: {
      type: String,
      required: true,
    },
    logEtapas: {
      type: Object as PropType<GetUsuarios>,
      required: true,
    },
  },
  components: {
    ContentLoader
  },
  setup(props) {
    const logs = ref();
    const maiorVersao = ref(0);
    const logProp = ref({});
    const animate = ref(true);
    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
    const dominio_orcamento = store.getters.layoutConfig("distribuidor.dominio_orcamento");
    const search = ref('')
    const delayTimer = ref();
    const data = reactive({
      current_page: 1,
      data: [
        {
          acesso: 0,
          id: 0,
          codConcessionaria: 0,
          login: 0,
          data: '',
          ip: 0,
          versao: 0,
          devicename: '',
          so: 0,
          devicemodel: '',
          descricao: '',
          tipo: 0
        }
      ],
      first_page_url: "",
      from: 1,
      last_page: 7,
      last_page_url: "",
      links: [],
      next_page_url: "",
      path: "",
      per_page: 10,
      prev_page_url: null,
      to: 0,
      total: 0
    });

    watch(() => props.logEtapas, () => {
      animate.value = true
      const logProp2 = props.logEtapas
      logProp.value = logProp2
      animate.value = false
      data.to = 1
      search.value = ''
      paginate()
    }
    );

    function formatarData(data) {
      const dataFormatada = new Date(data);
      const dia = dataFormatada.getDate().toString().padStart(2, '0');
      const mes = (dataFormatada.getMonth() + 1).toString().padStart(2, '0'); // Mês começa em 0
      const ano = dataFormatada.getFullYear();
      const hora = dataFormatada.getHours().toString().padStart(2, '0');
      const minuto = dataFormatada.getMinutes().toString().padStart(2, '0');
      const segundo = dataFormatada.getSeconds().toString().padStart(2, '0');

      return `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
    }


    function closeModal() {
      const refModal = document.getElementById(props.id);
      hideModal(refModal);
    }

    function filterData(){
      clearTimeout(delayTimer.value);
      delayTimer.value = setTimeout(function() {
        paginate(1, true);
      }, 300);
    }

    
    function paginate(page = 1, filter = false) {
      let baseDadosConcessionaria;

      if(filter && search.value){
        baseDadosConcessionaria = props.logEtapas.log.filter(concessionaria => 
            concessionaria.data.toLowerCase().includes(search.value.toLowerCase())   
         || concessionaria.descricao.toLowerCase().includes(search.value.toLowerCase())       
         || concessionaria.devicemodel.toLowerCase().includes(search.value.toLowerCase())    
         || concessionaria.devicename.toLowerCase().includes(search.value.toLowerCase())    
         || concessionaria.login.toString().toLowerCase().includes(search.value.toLowerCase()) 
         || concessionaria.ip.toString().toLowerCase().includes(search.value.toLowerCase())    
        )         
      } else {
        baseDadosConcessionaria = props.logEtapas.log
      }

      data.total = baseDadosConcessionaria.length;
      data.last_page = Math.ceil((baseDadosConcessionaria.length / data.per_page)) ?? 0;

      data.current_page = page;
      const start = data.per_page * (page - 1);
      const end = data.per_page * (page);
      if (data.last_page == page) {
        data.to = baseDadosConcessionaria.length;
      } else if (end > baseDadosConcessionaria.length) {
        data.to = baseDadosConcessionaria.length;
      } else {
        data.to = end;
      }

      data.data = baseDadosConcessionaria.slice(start, end);
    }

    return {
      logs,
      animate,
      dominio_orcamento,
      logProp,
      maiorVersao,
      closeModal,
      formatarData,
      distribuidor,
      data,
      paginate,
      search,      
      filterData
    };
  },
};
