
import { defineComponent, ref} from 'vue';
import useEmitter from '@/composables/Emmiter';
import AcessosLogAppCard from './components/AcessosLogAppCard.vue'
import ChecklistLogCard from './components/ChecklistLogCard.vue'
import OfertaPacotesCard from './components/OfertaPacotesCard.vue'
import { useStore } from "vuex";
import { ContentLoader } from 'vue-content-loader';
import { getIndex, graficoofertapacotes, graficoutilizacaoapp } from '@/services/LogAcessosApp';
import moment from "moment";
import VisualizarLog from "./components/VisualizarLog.vue"; 

export default defineComponent({
  name: "log-acessos",
  components: {
    ContentLoader,
    AcessosLogAppCard,
    ChecklistLogCard,
    OfertaPacotesCard,
    VisualizarLog
  },

  setup() {    
    const postData = ref();
    const usuarioMaisAcessos = ref()
    const emitter = useEmitter();
    const dadosOfertaLog:any = ref([]);
    const utilizacaoApp:any = ref([]);
    const codConcessionaria = ref(null);
    const historicoLogApp = ref("");
    const lastSelectedPage = ref(0);
    const loadingTable = ref(true);
    const verificaDiario = ref();
    const dataInicialFormatado = ref();
    const dataFinalFormatado = ref();
    const rowperpage = ref(20);
    const modalLog = ref();
    const usuarioComMaisAcessosReal = ref();
    const ofertaComMaisQuantidadeReal = ref();
    const usuarionumeroAcessos = ref();
    const numeroAcessos = ref();
    const dadosTabela = ref();
    const store = useStore();
    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
    const headers = ref([
      {
          name: "Nome",
          key: "nome",
          sortable: false,
      },
      {
          name: "Quantidade de Acessos",
          key: "acessos",
          sortable: false,
      },
    ]);

    emitter.on("filtrar-log-acessos", (filtroInfo) => {
      distribuidor
      codConcessionaria.value = filtroInfo.codConcessionaria;
      trataNomePeriodo(filtroInfo); 
      const dataPeriodo = filtroInfo.data;

      // Extrair as datas inicial e final da string
      const [dataInicialStr, dataFinalStr] = dataPeriodo.split('/');

      const dataInicial = new Date(dataInicialStr);
      const dataFinal = new Date(dataFinalStr);

      // Verifique se o filtro do usuário se refere a um único dia, mês e ano
      if (
        dataInicial.getDate() === dataFinal.getDate() &&
        dataInicial.getMonth() === dataFinal.getMonth() &&
        dataInicial.getFullYear() === dataFinal.getFullYear()
      ) {
        verificaDiario.value = true; // Mostrar o card
      } else {
        verificaDiario.value = false; // Ocultar o card
      }    
      listarLogAcessosApp(0,filtroInfo)
    });

    function trataNomePeriodo(filtroData){
       if(!filtroData){
        historicoLogApp.value = "";
        return;
      }

      const dataString = filtroData.data;
      const [dataInicial, dataFinal] = dataString.split('/');

      dataInicialFormatado.value = moment(dataInicial, "YYYY-MM-DD HH:mm:ss");
      dataFinalFormatado.value = moment(dataFinal, "YYYY-MM-DD HH:mm:ss");

      const auxDataInicial =  dataInicialFormatado.value ;
      const auxDataFinal = dataFinalFormatado.value;

       if(auxDataInicial.isSame(auxDataFinal, "day") && moment().isSame(auxDataInicial, "day")){
          historicoLogApp.value = `hoje`;
      } else if (auxDataInicial.isSame(auxDataFinal, "day")) {
          historicoLogApp.value = `${auxDataInicial.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")}`;
      } else {
          historicoLogApp.value = `${auxDataInicial.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")} até ${auxDataFinal.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")}`;
      } 
    }

    async function listarLogAcessosApp( page = lastSelectedPage.value, filtroInfo){
      loadingTable.value = true;
      lastSelectedPage.value = page;

      const dataString = filtroInfo.data;
      const [dataInicial, dataFinal] = dataString.split('/');
      let auxDataInicial = dataInicial;
      let auxDataFinal = dataFinal;
      const response = await getIndex(filtroInfo.codConcessionaria , auxDataInicial, auxDataFinal);  
      dadosTabela.value = response.usuarios.filter(usuario => usuario.qtdAcessos > 0)
      await encontrarUsuarioComMaisAcessos(response)
      await graficoUtilizacaoApp(filtroInfo, auxDataInicial, auxDataFinal)
      loadingTable.value = false;
    };
 

    async function graficoUtilizacaoApp(filtroInfo, auxDataInicial, auxDataFinal){
      const responseGrafico2graficoutilizacaoapp = await graficoutilizacaoapp(filtroInfo.codConcessionaria, auxDataInicial, auxDataFinal);
      utilizacaoApp.value = responseGrafico2graficoutilizacaoapp.graficoDados
      encontrarOfertaComMaisQuantidade(responseGrafico2graficoutilizacaoapp.graficoDadosOferta)
      dadosOfertaLog.value = responseGrafico2graficoutilizacaoapp.graficoDadosOferta
    }    

    async function encontrarUsuarioComMaisAcessos(array) {
      if (!array.usuarios || array.usuarios.length === 0) {
        return null; // Retorna null se o array de usuários estiver vazio ou indefinido
      }
      let usuarioComMaisAcessos = array.usuarios[0]; // Começa com o primeiro usuário como o de maior acesso
      for (let i = 1; i < array.usuarios.length; i++) {
        const usuarioAtual = array.usuarios[i];
        // Verifica se o usuário atual tem mais acessos do que o usuário com mais acessos atual
        if (usuarioAtual.qtdAcessos > usuarioComMaisAcessos.qtdAcessos) {
          usuarioComMaisAcessos = usuarioAtual;
        }
      }
        usuarioComMaisAcessosReal.value = usuarioComMaisAcessos.nome
        usuarionumeroAcessos.value = usuarioComMaisAcessos.qtdAcessos
    }

    function encontrarOfertaComMaisQuantidade(array) {
      if (!array || array.length === 0) {
        return null; // Retorna null se o array estiver vazio ou indefinido
      }
      let ofertaComMaisQuantidade = array[0]; // Começa com a primeira oferta como a de maior quantidade
      for (let i = 1; i < array.length; i++) {
        const ofertaAtual = array[i];    
        // Verifica se a oferta atual tem mais quantidade do que a oferta com mais quantidade atual
        if (ofertaAtual.qtd > ofertaComMaisQuantidade.qtd) {
          ofertaComMaisQuantidade = ofertaAtual;
        }
      }
      ofertaComMaisQuantidadeReal.value =  ofertaComMaisQuantidade;
    }

    return{
      numeroAcessos,
      dadosTabela,
      usuarioComMaisAcessosReal,
      ofertaComMaisQuantidadeReal,
      usuarionumeroAcessos,
      historicoLogApp,
      dataInicialFormatado,
      rowperpage,
      loadingTable,
      listarLogAcessosApp,
      encontrarUsuarioComMaisAcessos,
      postData,
      emitter,
      dadosOfertaLog,
      utilizacaoApp,
      headers,
      codConcessionaria,
      trataNomePeriodo,
      usuarioMaisAcessos,
      modalLog,
      distribuidor,
      verificaDiario
    }
  
  }
});
